@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
#26242C
#FFF67D
#91E2EB
#FF7272
#91EBB5 
#3e3a47
*/
body {
  font-family: "Anonymous Pro", "Courier New", Courier, monospace;
  color: #ffffff;
  /* /* background-color: #26242C; */
  background-color: #0f0e0f;
  overflow: hidden;
  overflow-x: hidden;
}

::selection {
  background-color: #57c1ff;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3e3a47;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ea5966;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ece253;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
}

h2 {
  font-size: 2rem;
  margin: 15px 0px;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 10px 0px;
}

h4 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 5px 0px;
  /* color: #$color2; */
}

h4 a {
  /* color: #ff7272; */
  color: #f3c78f;
}

h5 {
  font-size: 1.1rem;
  font-weight: 600;
}

p {
  color: white;
}

#title {
  color: #ea5966;
}

#title:hover {
  cursor: pointer;
  color: #efdbc0;
}

#navbar {
  box-sizing: content-box;
  display: flex;
  flex-wrap: nowrap;
  align-content: space-between;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding-top: 20px;
  margin: auto;
  position: relative;
}

#navbar-links ul {
  display: flex;
}

#navbar-links ul li {
  font-size: 1.8rem;
  font-weight: 600;
  color: #ea5966;
  z-index: 100;
  margin: 10px 20px;
  cursor: pointer;
  transition: 0.5s;
}

#navbar-links ul li:hover {
  color: #efdbc0;
}

.tab-appear {
  position: relative;
  opacity: 0;
  left: 500px;
  transition: 1000ms;
}

.tab-enter {
  /* transform: scale(0.8); */
  position: relative;
  opacity: 0;
  left: 500px;
  /* animation: slide-in 3s 1;  */
}

.tab-enter-active {
  /* transform: scale(0.8); */
  position: relative;
  opacity: 1;
  left: 0px;
  transition: 1000ms;
  /* animation: slide-in 3s 1; */
}

.tab-exit {
  /* transform: scale(0.8); */
  position: relative;
  /* animation: slide-out 3s 1; */
}

.tab-exit-active {
  /* transform: scale(1); */
  position: relative;
  /* left: -500px; */
  transition: 1000ms;
  /* animation: slide-out 3s 1; */
}

.tab-exit-done {
  position: relative;
  /* left: -500px; */
  /* opacity: 1; */
  /* transition: 1000ms; */
  /* left: -500px; */
}

@keyframes slide-in {
  0% {
    left: 500px;
  }
  100% {
    left: 0px;
  }
}

@keyframes slide-out {
  0% {
    left: 0px;
  }
  100% {
    left: -500px;
  }
}

.tab {
  color: #ea5966;
  width: 90%;
  height: 90%;
  min-height: 70vh;
  max-height: calc(100vh - 156px);
  margin: auto;
  margin-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialMediaIcons {
  display: flex;
}

/* resume part */
.resumePair {
  display: flex;
}

.pairItem {
  margin: 10px 20px;
  color: white;
}

.pairItem ul li {
  list-style: circle;
}

/* gallery */
.projectGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.projectDescription {
  margin: 15px 0px;
  width: 60%;
  min-width: 400px;
  max-width: 900px;
}

.imageGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.imageContainer {
  margin: 5px;
  display: inline-block;
  height: 400px;
  width: auto;
}

.image15 {
  display: block;
  height: 400px;
  width: 400px;
  /* border: 1px solid salmon; */
  background-image: url(/static/media/15a.603e470e.png);
  background-size: cover;
  transition: 0.5s;
}

.image15:hover {
  background-image: url(/static/media/15b.cb8c81f1.png);
}

img {
  height: 100%;
  width: auto;
}

.galleryViewMore {
  margin: 25px auto;
  margin-bottom: 15px;
  text-align: center;
}

.galleryModal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0f0e0f;
  background-color: rgba(18, 16, 16, 0.75);
  height: 95vh;
  max-height: 95vh;
  width: 90vw;
  overflow: hidden;
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 1.25vh auto;
}

.modalCloseIcon {
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

/* contact */
span {
  color: white;
}

.socialmedia-icon {
  display: inline-block;
  padding: 10px;
  fill: #ea5966;
  width: 50px;
  height: auto;
}

.socialmedia-icon a {
  fill: #ea5966;
  transition: 0.5s;
}

.socialmedia-icon a:hover {
  fill: #efdbc0;
}

.socialmedia-icon svg {
  width: 100%;
  height: 100%;
}

.tabHome {
  /* display: block; */
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  /* border: 1px solid red;  */
  border-radius: 35px;
  margin: auto;
  overflow: visible;
}

canvas {
  margin: auto;
  max-width: 99%;
  height: 800px;
  max-height: 800px;
  /* background: lightcoral; */
  background: radial-gradient(circle, black 0%, black 30%, #441616 60%, rgba(18, 16, 16, 0) 76%);
}

.tabIntro {
  margin: 0px auto;
  -ms-grid-row-align: center;
  align-self: center;
  margin-bottom: 20px;
}

.tabRead {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}

.tabRead .readCard {
  display: flex;
  margin-bottom: 20px;
  text-decoration: none;
  color: #ea5966;
}

.tabRead .readCard .cardInfo {
  padding: 20px 0px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
}

.tabRead .readCard .cardTitle {
  color: white;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.clickNotification {
  display: block;
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 15%;
  margin-left: -35px;
  justify-content: center;
  animation: clickNotFadeIn 6s 1 forwards;
  pointer-events: none;
}

.clickNotification p {
  text-align: center;
  align-content: center;
  letter-spacing: 0.5rem;
  cursor: default;
}

.clickNotification .clickNotificationCircle {
  position: absolute;
  border: 1px solid white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: 24px;
  top: -10px;
}

.clickNotification #circle1 {
  animation: circleGrow 5s 1 forwards;
}

.clickNotification #circle2 {
  animation: circleGrow 5s 1 forwards;
  animation-delay: 2s;
}

@keyframes clickNotFadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  60% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    display: hidden;
  }
}

@keyframes circleGrow {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(4);
    display: hidden;
  }
}

@media only screen and (max-width: 1024px) {
  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
  .tabRead .readCard {
    flex-direction: column;
  }
  .tabRead .readCard .cardInfo {
    margin-left: 0px;
    margin-top: 20px;
  }
  .imageGallery {
    flex-direction: column;
    align-items: center;
  }
  .imageGallery .imageContainer {
    height: 100%;
    position: relative;
  }
  .imageGallery .imageContainer img {
    position: relative;
    max-height: 600px;
    width: auto;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tabHome {
    width: 99%;
    height: 99%;
    margin: 1px;
    overflow: hidden;
  }
  canvas {
    height: 700px;
    max-height: 700px;
  }
  #navbar-links ul li {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 512px) and (max-width: 768px) {
  canvas {
    max-height: 600px;
  }
  h1 {
    font-size: 2rem;
  }
  #navbar-links ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: right;
  }
  #navbar-links ul li {
    font-size: 1.2rem;
    margin: 7px 0px;
  }
}

@media only screen and (max-width: 512px) {
  h1 {
    font-size: 2rem;
  }
  canvas {
    max-height: 600px;
    bottom: 0px;
  }
  #navbar-links ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: right;
  }
  #navbar-links ul li {
    font-size: 1rem;
    margin: 5px 0px;
  }
}
/*# sourceMappingURL=App.css.map */
